.wrapper {
  display: flex;
}

.root {
  display: flex;
  position: relative;
  margin: auto;
  overflow: hidden;
  user-select: none;
  font-size: 10px;
}

.current {

}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 10px;

  &:first-of-type {
    .index {
      &:before {
        display: none;
      }
    }
  }
  &:last-of-type {
    .index {
      &:after {
        display: none;
      }
    }
  }

  &.completed {
    .index {
      color: white;
      background-color: var(--success);
      border-color: var(--success);
    }
    .index {
      &:before {
        background-color: var(--success);
      }
    }
    &:not(&.current) {
      .index {
        &:after {
          background-color: var(--success);
        }
      }
    }
  }
}

.index {
  border: 1px solid var(--textDisabled);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg);
  color: var(--textDisabled);
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  &:before {
    content: "";
    height: 1px;
    background-color: var(--textDisabled);
    position: absolute;
    width: 50%;
    left: 0px;
    z-index: -1;
    top: 10px;
  }
  &:after {
    content: "";
    height: 1px;
    background-color: var(--textDisabled);
    position: absolute;
    width: 50%;
    left: 50%;
    z-index: -1;
    top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .title {
    display: none;
  }
}
