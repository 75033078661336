.root {
  display: flex;align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  justify-content: flex-end;

  .checkBox {
    width: 20px;
    margin-right: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media only screen and (max-width: 440px) {
  .root {
    justify-content: flex-start;
    padding-left: 4px;
    height: 32px;
  }
}
