.buttons {
  display: flex;
  justify-content: flex-end;
}

.header {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 24px;
}

.error {
  height: 12px;
  margin-bottom: 8px;
  font-size: 12px;
  color: var(--error);
}

.inputWrapper {
  margin-top: 8px;
}

.btn {
  height: 32px;
  border: 1px solid var(--inputBorder);
  padding: 0px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 8px;
  border-radius: 6px;

  &.primary {
    background-color: var(--themePrimary);
    color: var(--bg);
    border-color: var(--themePrimary);
  }

  &:disabled {
    color: var(--textDisabled);
    background-color: var(--gray20);
    border-color: var(--gray20);
  }
}

.between {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
}

.dialog {
  width: 360px;
  border-radius: 6px;
  z-index: 100;
}
