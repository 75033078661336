.root {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 100% 1 1;
  background-color: var(--bg);
  border-radius: 16px;
  position: relative;
}

.footer {
  display: flex;
  height: 36px;
  flex-shrink: 0;
  border-top: 1px solid var(--divider);
  align-items: center;
  color: var(--textSecondary);
  padding-left: 20px;
}

.header {
  font-weight: 600;
  font-size: 28px;
  line-height: normal;
  display: flex;
  word-break: break-word;
}

.subHeader {
  font-weight: 400;
  font-size: 12px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.headerBox {
  display: flex;
  position: relative;
  padding: 20px 20px 0 20px;

  .infoWrapper {
    display: flex;
  }

  .placeholder {
    height: 72px;
    margin-right: 20px;
    align-self: flex-start;
  }

  .rightBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.strong {
  word-break: break-word;
}

.data {
  padding: 10px 20px 20px 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  color: var(--textPrimary);
  overflow: auto;
}

.dataBlock {
  margin-bottom: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: min-content;
  gap: 16px 24px;
  flex-direction: column;

  padding: 24px;
  overflow: hidden;

  div {
    overflow: hidden;
  }
}

.blockWrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  box-shadow: var(--smallShadow);
  background-color: white;
  border-radius: 16px;
}

.dataHeader {
  font-size: 20px;
  line-height: 28px;
  grid-column: 1/-1;
}

.label {
  font-size: 12px;
  line-height: 16px;
  color: var(--textSecondary);
  margin-bottom: 4px;
}

.value {
  font-size: 14px;
  line-height: 20px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  //max-height: 60px;
  white-space: normal;
  word-break: break-word;

  a {
    word-break: break-word;
    white-space: normal;
  }
}

.html {
  overflow: auto;
  max-height: 60px;
  white-space: normal;
}

.long {
  grid-column: 1/-1;
  overflow: hidden;
}

.panel {
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.long {
  grid-column: 1/-1;
}

.mobileList {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  overflow-y: auto;
}

.listItem {
  display: flex;
  flex: 0 0 57px;
  border-bottom: 1px solid var(--divider);
}

.listItemContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 10px;
  justify-content: flex-start;
  line-height: 16px;
  font-size: 12px;
}

.mobileRing {
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--theme3rd);
  text-transform: uppercase;
  line-height: 32px;
  align-self: center;
}

.link {
  color: var(--themePrimary);
  font-weight: 600;
}

.loader {
  position: fixed;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loaderAbsolute {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgTransparent);
  z-index: 2;
}

.placeholder {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  align-self: center;

  &.emptyImage {
    border: 1px solid var(--textPrimary);
  }

  & img {
    width: 100%;
  }

  svg {
    fill: var(--textPrimary);
  }
}

.draftBlock {
  display: flex;
  position: absolute;
  right: 0;
  padding-right: 6px;
  color: var(--textSecondary);
  align-items: center;
  font-size: 14px;

  > div {
    margin-left: 5px;
  }

  svg {
    fill: var(--textPrimary);
  }
}

.ckeditor {
  * {
    white-space: normal;
    word-break: break-word;
  }

  table {
    border-collapse: collapse;
    td {
      padding: 5px 5px;
      border: 1px solid var(--textSecondary);
    }
  }

  ul {
    padding-left: 10px;
    margin: 0;
    li {
      list-style-position: inside;
    }
  }

  ol {
    margin: 0;
    padding-left: 10px;
    counter-reset: myCounter;
    li {
      list-style: none;
    }

    li:before {
      counter-increment: myCounter;
      content: counter(myCounter);
      display: inline-block;
      text-align: center;
      margin-right: 10px;
      font-weight: 600;
    }
  }
}

.lineBreak {
  white-space: pre-line;
}

.statusFieldsWrapper {
  display: flex;
  margin-left: auto;
  align-self: center;
  &.leftSide {
    margin-left: 0;
    align-self: flex-start;
    margin-top: 16px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    border-right: 1px solid var(--divider);
    & > div {
      white-space: nowrap;
    }
    &:last-of-type {
      border: none;
    }
    &:first-of-type {
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .data {
    grid-template-columns: 100%;
    padding: 15px;
  }
  .dataBlock {
    padding: 15px;
  }
  .placeholder {
    width: 72px;
    height: 72px;
  }
  .headerBox {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    .rightBlock {
      align-self: flex-start;
    }
    .header {
      justify-content: left;
      font-size: 18px;
    }

    .draftBlock {
      position: relative;
    }
  }
  .statusFieldsWrapper {
    width: 100%;
    flex-direction: column;
    margin-left: 0;
    & > div {
      border: none;
      padding: 0;
      margin-bottom: 4px;
      flex-direction: row-reverse;
      align-self: stretch;
      div {
        flex: 0 0 45%;
        &:first-of-type {
          flex: 0 0 55%;
        }
        white-space: normal;
      }
    }
  }
}

.tabBtnWrapper {
  display: flex;
  padding: 0 20px;
  margin-top: 10px;
  flex-shrink: 0;
  overflow-x: auto;
  position: relative;
  border-bottom: 1px solid var(--divider);
}

.tabBtn {
  font-size: 14px;
  line-height: 40px;
  margin-right: 20px;
  color: var(--textPrimary);
  font-weight: 400;
  white-space: nowrap;
  overflow: visible;
  position: relative;
  &.selected {
    font-weight: 600;
    &:before {
      content: '';
      position: absolute;
      background-color: var(--themePrimary);
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
    }
  }
}

.tabsWrapper {
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 16px;
  position: relative;
}

.isDisabled {
  .blockWrapper {
    border: none;
    box-shadow: none;
  }
  .data {
    opacity: 0.8;
  }
  .tabsWrapper {
    opacity: 0.8;
  }
  & .placeholder img {
    opacity: 0.4;
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1023px) {
  .headerBox {
    flex-wrap: wrap;
  }
  .statusFieldsWrapper {
    width: 100%;
    margin-left: 0;
    margin-top: 25px;
    & > div {
      max-width: 260px;
    }
  }
}

.showMoreBtn {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  color: var(--themePrimary);
  width: 100%;
}

.tsWrapper {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  .checkBox {
    width: unset;
    margin-right: 10px;
  }
  .tsButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1023px) {
  .statusFieldsWrapper {
    margin-top: 12px;
  }
  .headerCollapsed {
    overflow: hidden;
    .rightBlock {
      min-width: 150px;
      max-height: 72px;
      justify-content: start;
    }
  }
  .root {
    position: static;
  }
}
