.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 400px;
}

.cellsWrapper {
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.label {
  line-height: 20px;
}

.preview {
  background-color: var(--bg);
  opacity: 0.9;
  width: calc(100vw - 48px);
  position: fixed;
  z-index: 1;
  box-shadow: var(--shadow);
  max-width: 352px;
}

.cell {
  height: 38px;
  padding: 9px 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 14px;
  line-height: 38px;
  &.toggle {
    border-bottom: 1px solid var(--divider);
    padding: 12px 0;
  }

  button {
    display: flex;
    align-items: center;
  }

  .dots {
    margin-left: auto;
    fill: var(--textSecondary);
  }

  &.hovered {
    opacity: 0.5;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .controls {
    display: flex;
    margin-left: auto;
    flex-shrink: 0;
  }
}

.menuButton {
  font-weight: 400;
}
.pinBtn {
  margin-right: 12px;
}

.pin {
  fill: var(--textSecondary);
  align-items: center;

  &.enabled {
    fill: var(--themePrimary);
  }
}

@media only screen and (max-width: 440px) {
  .root {
    width: 100vw;
    height: 100%;
  }
}
