.progressWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 17px;
  flex-direction: column;
}

.errors {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--divider);
  padding: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 512px;
  max-width: 100%;
  max-height: 266px;
}

.error {
  height: 24px;
  padding: 0 6px;
  margin: 4px;
  border-radius: 12px;
  background-color: var(--bgDisabled);
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 14px;
  .errorLabel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.iconWrapper {
  display: flex;
  margin-left: 14px;
}

.header {
  line-height: 28px;
  font-size: 20px;
  margin-top: 8px;
  font-weight: 600;
}

.info {
  margin-top: 12px;
  color: var(--textSecondary);
  font-size: 14px;
}

.footer {
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
}

.description {
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 14px;
}
