@import "src/components/Form/Input/input.module";

.wrapper {
  grid-column: 1/-1;
}

.placeholder {
  color: var(--textPrimary);
  border: 1px dashed var(--inputBorder);
  border-radius: 6px;
  height: 93px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  &.over {
    border-color: var(--themePrimary);
    background-color: var(--primaryBg);
  }
  &.withError {
    border-color: var(--error);
  }
  .link {
    color: var(--themePrimary);
  }
  .description {
    font-size: 12px;
    color: var(--textSecondary);
  }
}

.fileWrapper {
  &.collapsed {
    max-height: 240px;
    overflow: hidden;
  }
}

.more {
  font-size: 14px;
  font-weight: 600;
  color: var(--themePrimary);
  margin-top: 6px;
}
