.root {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 600px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.snackRoot {
  display: flex;
  padding: 14px 49px 14px 44px;
  box-shadow: var(--bigShadow);
  margin-top: 10px;
  position: relative;
  background-color: #FFFFFF;
  flex-direction: column;
  animation: fadeIn 100ms linear;
  border-radius: 6px;
  border: 1px solid #3231300F;

  &.warning {
    background-color: #FFF4CE;
  }
  &.error {
    background-color: #FDE7E9;
  }
  &.success {
    background-color: #DFF6DD;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    & > button {
      margin-top: 12px;
      border: 1px solid rgba(0, 0, 0, 0.2)!important;
    }
  }
}

.icon {
  position: absolute;
  left: 16px;
  top: 16px;

}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px!important;
  height: 16px!important;
  padding: 0!important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 425px) {
  .root {
    padding: 24px 15px;
    max-width: 100vw;
  }
  .title {
    font-size: 14px;
  }
  .content {
    font-size: 12px;
  }
}
