.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
}

.footer {
  display: flex;
  height: 36px;
  flex-shrink: 0;
  border-top: 1px solid var(--divider);
  align-items: center;
  color: var(--textSecondary);
  font-size: 12px;
  filter: drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13));
  padding-left: 5px;
}

.loader {
  flex-shrink: 0;
  margin: auto 0;
  position: sticky;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    height: 125px;
    margin-bottom: 12px;
  }
  p {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--textPrimary);
  }
}
