.label {
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
  color: var(--textPrimary);
}

.note {
  display: flex;
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  svg {
    margin-right: 5px;
    flex-shrink: 0;
  }
}
