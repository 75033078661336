:root {
    --bg: #fff;
    --bgTransparent: #ffffffaa;
    --divider: #00000030;
    --textPrimary: #323130;
    --textSecondary: #605E5C;
    --textDisabled: #A19F9D;
    --bgDisabled: #F3F2F1;
    --textAlt: #fff;
    --themePrimary: #0078D4;
    --darkAlt: #106EBE;
    --theme3rd: #2B88D8;
    --primaryBg: #EFF6FC;
    --rowHover: #DEECF9;
    --error: #A4262C;
    --errorBg: #FDE7E9;
    --warningBg: #FDEBE0;
    --inputBorder: #8A8886;
    --gray20: #F3F2F1;
    --mobile: 375px;
    --header: #4B335C;
    --tableBgAlt: #FAF9F8;
    --shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    --smallShadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    --bigShadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
    --accountInfoShadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
    --success: #6BB700;
}
