.root {
  flex-shrink: 0;
  height: 33px;
  border-top: 1px solid var(--divider);
}

.btn {
  color: var(--themePrimary);
  width: 100%;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  svg {
    fill: var(--themePrimary) !important;
  }
}
