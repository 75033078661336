.pagination {
  background-color: transparent;
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 32px;
  overflow: hidden;
}

.pagesSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  .select {
    height: 24px;
    width: 56px;
    flex: 0 0 56px;
  }
  span {
    margin-right: 5px;
    font-weight: 400;
    font-size: 12px;
    color: var(--textSecondary);
  }
}

.btn {
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  min-width: 16px;
  height: 20px;
  margin: 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--textSecondary);
  border-bottom: 2px solid transparent;
  cursor: pointer;

  svg {
    fill: var(--textSecondary);
  }

  span {
    align-self: flex-start;
  }

  &:hover {
    svg {
      fill: var(--themePrimary);
    }
  }

  &:disabled {
    svg {
      fill: var(--textDisabled);
    }
  }
}

.active {
  border-bottom: 2px solid #0078D4;
  color: #000;
}
