.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.input {
  height: 32px;
  flex-shrink: 0;
  width: 100%;
  padding: 6px 8px;
  border: 1px solid var(--inputBorder);
  border-radius: 6px;
  resize: none;

  &.hasError {
    border-color: var(--error);
  }
}

textarea.input {
  height: auto;
}

.counter {
  margin-left: auto;
  padding-left: 10px;
  white-space: nowrap;
  font-size: 12px;
  color: var(--textSecondary);
  line-height: 16px;
}

.label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--textPrimary);
  &.readOnly {
    font-weight: 400;
    color: var(--textSecondary);
    font-size: 12px;
    line-height: 16px;
  }

  &:not(.disabled).required {
    &:after {
      content: '*';
      color: var(--error);
    }
  }
}

.error {
  color: var(--error);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  &.absolute {
    position: absolute;
    left: 0;
    top: 100%;
  }
}

.bottomWrapper {
  display: flex;
}

.value {
  font-size: 14px;
  line-height: 20px;
}
