.root {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  outline: none;
  border: 1px solid var(--textSecondary);
  &.active, &:hover {
    border-color: var(--themePrimary);
  }
}

.circle {
  display: flex;
  box-sizing: border-box;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  outline: none;
  flex-shrink: 0;
  background-color: var(--themePrimary);
}
