.wrapper {
    grid-area: content;
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    background: var(--bg);
}

.page {
  margin: 24px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  box-shadow: var(--shadow);
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 36px;
    font-weight: 600;
}

@media only screen and (max-width: 440px) {
  .page {
    margin: 15px 15px;
  }
}
