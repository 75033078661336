.root {
  box-shadow: var(--shadow);
  border-radius: 6px;
  padding: 24px;
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: var(--bg);
  z-index: 3;
  transform: translate(-50%, -50%);
  min-width: 200px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  color: var(--textSecondary);
  overflow: hidden;
  strong {
    color: var(--textPrimary);
  }
  &.fullScreen {
    width: 100vw;
    height: 100vh;
  }
  &.noPadding {
    padding: 0;
  }
}

.fixed {
  .children {
    flex: 1 1 100%;
  }
  .controls {
    padding-top: 0;
  }
}

.overlay {
  content: '';
  width: 100vw;
  height: 100%;
  background-color: var(--bg);
  opacity: 0.4;
  left: 0;
  top: 0;
  display: block;
  position: fixed;
  z-index: 3;
}

.header {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  padding-bottom: 20px;
  color: var(--textPrimary);
  padding-right: 30px;
  text-transform: capitalize;
}

.content {
  color: var(--textSecondary);
  padding-bottom: 20px;
  overflow: hidden;
  line-height: 20px;
  font-size: 14px;
}

.children {
  line-height: 20px;
  font-size: 14px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;
  flex: 56px 0 0;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;

  & button,
  & input {
    margin-left: 8px;
  }
}

.loader {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgTransparent);
  z-index: 2;
}

.buttons {
  right: 24px;
  top: 24px;
  position: absolute;
  z-index: 1;
  display: flex;
  button {
    margin-left: 10px;
  }
}

.noPadding {
  .header {
    padding: 24px 24px;
  }
  .controls {
    padding: 0px 24px;
  }
}

@media only screen and (max-width: 440px) {
  .root {
    width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    padding: 16px;
    &.fullScreen {
      width: 100vw;
      max-height: 100%;
    }
  }
  .controls {
    padding-top: 16px;
    margin-top: auto;
  }
}

.collapsed {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;
  justify-content: center;
  box-shadow: var(--shadow);
  border-radius: 6px;
  border: 1px solid;
  border-color: var(--inputBorder);
  right: 25px;
  bottom: 30px;
  width: 320px;
  height: 62px;
  font-weight: 600;
  font-size: 18px;
  padding: 14px;
  background-color: rgba(255, 255, 255, 1);
}
