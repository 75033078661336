.wrapper {
  display: flex;
  position: relative;
  width: 270px;
  padding: 5px;
  flex-shrink: 0;
}

.icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--themePrimary);
  }
}

.bar {
  height: 32px;
  flex-grow: 1;

  span {
    display: flex;
  }
}

.toolTip {
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 10px;
  }

  .list {
    li {
      list-style: disc inside none;
      display: list-item;
      margin-left: 1em;
    }
  }
}

@media only screen and (max-width: 440px) {
  .wrapper {
    width: 100%;
    flex-grow: 1;
  }
}
