.wrapper {
  margin: 0px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.errorHolder {
  z-index: 1;
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  &.relative {
    width: 100%;
    position: relative;
    left: 0;
  }
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 20px;
  font-size: 14px;
}

.header {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.long, .header {
  grid-column: 1/-1;
}

.controls {
  margin-top: auto;
  display: flex;
}

.btn {
  padding: 6px 20px;
  height: 32px;
  border-radius: 6px;
  margin-right: 16px;
  border: 1px solid var(--inputBorder);
  font-weight: 600;

  &.submit {
    background: var(--themePrimary);
    border: none;
    color: #ffffff;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
  position: relative;
  padding: 0 20px;
  height: 100%;
}

.idn {
  align-self: center;
  display: flex;
  flex-direction: column;
  width: min-content;
  justify-self: right;

  .label {
    font-size: 12px;
    color: var(--textSecondary);
  }

  .id {
    color: var(--textPrimary);
  }
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgTransparent);
  z-index: 1;
}

.panel {
  width: 560px;
}

@media only screen and (max-width: 440px) {
  .formGrid {
    display: flex;
    flex-direction: column;
  }
  .idn {
    align-self: flex-start;
    //order: -1;
  }
}
