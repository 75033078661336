.button {
  padding: 0 20px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid var(--inputBorder);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: capitalize;

  &.primary {
    color: #fff;
    background-color: var(--themePrimary);
    border-color: var(--themePrimary);

    &:hover:not(:disabled) {
      background-color: var(--darkAlt);
      border-color: var(--darkAlt);
    }

    &:disabled {
      color: #fff;
    }
  }

  &:disabled {
    color: var(--textDisabled);
    background-color: var(--bgDisabled);
    border-color: var(--bgDisabled);
  }
}
