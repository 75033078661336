.emailTemplatesButton {
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--themePrimary);
  cursor: pointer;
  display: block;
  flex-direction: row-reverse;
  right: 0px;
  top: 0;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: -1;
  position: relative;
  align-items: flex-end;
  height: 0px;
  z-index: 0;
}

.footer {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 100%;
}

.warning {
  max-width: 330px;
  font-size: 14px;
}

.content {
  border-top: 1px solid #edebe9;
  border-bottom: 1px solid #edebe9;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}

.list {
  overflow: auto;
}

.leftPanel {
  display: flex;
  flex-direction: column;
  width: 274px;
}

.rightPanel {
  flex: 1;
  background-color: var(--bgDisabled);
  display: flex;
  justify-content: center;
  border-left: 1px solid #e9ede9;
  overflow: auto;
  padding-bottom: 20px;
}

.blockWrapper {
  display: table;
  box-shadow: var(--smallShadow);
  background-color: white;
  border-radius: 16px;
  max-height: 20%;
  width: 90%;
  margin-top: 25px;
  overflow: auto;
  padding: 24px;
}

.label {
  font-size: 12px;
  line-height: 16px;
  color: var(--textSecondary);
  margin-bottom: 4px;
}

.value {
  font-size: 14px;
  font-weight: 600;
  color: var(--textPrimary);
  margin-bottom: 10px;
}
.subject {
  font-size: 18px;
  color: var(--textPrimary);
  margin-bottom: 10px;
}
.body {
  color: var(--textPrimary);
}
.btn {
  display: flex;
  justify-content: space-between;
}

.modal {
  width: 900px;
  height: 89%;
  padding: 0 !important;
}

.nodata {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 20px 0px 0px 20px;
  color: var(--textPrimary);
  font-weight: 600;
}

.search {
  width: 92%;
  margin: 20px 0px 20px 10px;
}

.element {
  border-bottom: 1px solid #e9ede9;
  padding: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  width: 96%;

  &.selected,
  &:hover {
    background-color: var(--primaryBg);
    border-radius: 6px;
  }
}

.cell {
  padding: 5px;
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loader {
  display: grid;
  place-items: center;
  height: 100%;
}

.blockHeader {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  flex-direction: column;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .leftPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .rightPanel {
    display: flex;
    flex-direction: column;
    width: 0%;
  }
  .search {
    padding: 2px;
    flex-grow: 0;
    margin: 30px 0px 10px 10px;
  }
  .list {
    overflow: auto;
  }
  .warning {
    display: block;
    font-size: 11px;
  }
  .btn {
    width: 100%;
    margin-top: 4px;
    button {
      width: 48%;
      margin: 0%;
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 1023px) {
  .modal {
    width: 90%;
    padding: 0 !important;
  }
}
