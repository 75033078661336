.actions {
  display: flex;
  align-items: center;
  margin-right: auto;
  overflow: hidden;
  &.selection {
    width: 100%;

    .spacer {
      padding: 6px 8px;
      margin: auto;
      height: 32px;
    }
  }
  .list {
    display: flex;
    overflow: hidden;
    flex: 0 1 auto;
    &.hidden {
      flex-grow: 1;
      visibility: hidden;
    }
  }
}

.withBorder {
  border-bottom: 1px solid var(--divider);
  background-color: inherit;
  height: 43px;
  flex-shrink: 0;
  margin-right: unset;
  padding: 0 5px;
}

.btn {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 20px;

  &:hover {
    outline: #0078D4;
  }
}

.menu {
  top: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  box-shadow: var(--shadow);
  border-radius: 6px;
}
