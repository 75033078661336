.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ul {
    padding-left: 30px;
    margin: 0;
  }

  ol{
    margin: 0;
    padding-left: 10px;
    counter-reset: myCounter;
    li{
      list-style-position: inside;
      display: flex;
    }

    li:before {
      counter-increment: myCounter;
      content:counter(myCounter);
      display: inline-block;
      text-align: center;
      margin-right: 10px;
      font-weight: 600;
    }
  }
}

.hasError {
  border: 1px solid var(--error);
}

textarea.input {
  height: auto;
}

.counter {
  margin-left: auto;
  padding-left: 10px;
  white-space: nowrap;
  font-size: 12px;
  color: var(--textSecondary);
  line-height: 16px;
}

.label {
  font-weight: 600;
  margin-bottom: 5px;

  &.required {
    &:after {
      content: '*';
      color: var(--error);
    }
  }
}

.error {
  color: var(--error);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.bottomWrapper {
  position: relative;
  display: flex;
}
