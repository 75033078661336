.root {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: static;
  width: 100%;
}

.background {
  background-color: var(--bg);
}

.imgWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 200px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0px;
  height: 40%;
  max-width: 200px;
  margin-top: 50px;
}

.errrImg {
  width: 100%;
  height: auto;
}

.errrText {
  max-width: 500px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 52px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
  color: var(--textSecondary);
}

@media only screen and (max-width: 440px) {
  .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  width: 290px;

  & button {
    margin-bottom: 8px;
  }
}

.contactEmail {
  position: fixed;
  bottom: 10px;
  color: var(--textSecondary);
  font-size: 12px;
}
