.root {
  width: 100%;
  background-color: var(--bg);
  padding: 24px;
  overflow: auto;
  border-radius: 16px;
}

.grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.block {
  height: 342px;
  overflow: hidden;
  position: relative;
  display: flex;
  box-shadow: var(--shadow);
  border-radius: 16px;
  padding: 20px 20px 5px 20px;
  background-color: var(--bg);
}

.long {
  grid-column: 1/-1;
}


.header {
  display: flex;
  justify-content: space-between;
  .text {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .controls {
    display: flex;
  }
}

@media only screen and (max-width: 440px) {
  .grid {
    grid-template-columns: 100%;
  }
  .block {
    max-height: 320px;
  }
  .root {
    padding: 12px;
  }
  .long {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .grid {
    grid-template-columns: 100%;
  }
  .block {
    max-height: 320px;
  }
  .root {
    padding: 12px;
  }
  .long {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}
