.modal {
  width: 90%;
  height: 90%;
  padding: 0 !important;
}

.dialogWrapper {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

.mainWrapper {
  flex-shrink: 0;
}
.duplicatesWrapper {
  overflow: hidden;
  flex: 1 1 100%;
  display: flex;
}

.table {
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: var(--smallShadow);
  border-radius: 6px;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: stretch;
}

.primary {
  background-color: var(--themePrimary);
  color: #fff;
  border: none !important;
  svg {
    fill: #fff;
  }
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  height: 64px;
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: var(--bgDisabled);
    left: 0;
    bottom: 64px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: var(--smallShadow);
  margin: 10px;
}
.line {
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  grid-gap: 2px;
  background-color: var(--tableBgAlt);
  border-bottom: 2px solid var(--tableBgAlt);
  color: var(--textPrimary);
  min-width: 470px;
  overflow: hidden;
  &:last-of-type {
    border-bottom: none;
  }
}

.label,
.field {
  box-sizing: border-box;
  display: flex;
  background-color: var(--bg);
  align-items: center;
  padding: 20px;
  height: 40px;
  position: relative;
  text-align: left;
  min-width: 152px;
  div:first-child {
    margin-right: 20px;
  }
  div:last-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
  &.head {
    color: var(--themePrimary);
  }
}

.fieldHighlight {
  .label,
  .field {
    background-color: var(--rowHover);
  }
}

.placeholder {
  width: 16px;
  display: block;
  flex-shrink: 0;
}

.label {
  font-weight: 600;
  &.withError {
    &:before {
      content: '';
      width: 2px;
      height: 100%;
      background-color: var(--error);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.errorLabel {
  width: 16px;
  height: 16px;
  color: white;
  background-color: var(--error);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 11px;
  margin-left: 10px;
}

.preview {
  padding: 12px 0;
  color: var(--textPrimary);
  background-color: #E8EAF6;
  flex-grow: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  border-radius: 16px;
  overflow: hidden;
  background-color: var(--bg);
  align-items: stretch;
  margin: 10px 20px;
  box-shadow: var(--shadow);
}

.sidesPadding {
  padding: 0 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  padding: 0 24px;
  .statusBlock {
    width: auto;
    margin-left: 0;
  }
  .headerTitle {
    color: var(--textSecondary);
    flex-shrink: 0;
    padding-right: 20px;
    margin-right: auto;
    max-width: 50%;
    min-height: 70px;
  }
  .h2 {
    color: var(--textPrimary);
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 1023px) {
  .header {
    flex-direction: column;
  }
}

.duplicates {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  .tWrapper {
    height: 232px;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
  }
}
.pages {
  display: flex;
  flex-shrink: 0;
  padding: 0px 10px;
  border-top: 1px solid var(--divider);
  font-size: 12px;
  align-items: center;
}

.loader {
  margin: auto 0;
  padding: 10px 0;
  position: sticky;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    height: 125px;
    margin: 0 12px;
  }
}

.scroll {
  overflow: auto;
  padding: 0 24px;
  flex-grow: 1;
  &.scrollPreview {
    padding: 0;
  }
}

.space {
  margin-bottom: 10px;
}

.manualBox {
  display: flex;
  align-items: center;
  height: 32px;
  flex-shrink: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: var(--textPrimary);
  .search {
    margin-left: auto;
  }
  .toggle {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    min-width: 470px;
    font-size: 12px;
    button {
      font-size: 12px;
    }
  }
  .footer {
    display: grid;
    gap: 8px;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    & > button {
      margin: 0;
      justify-content: center;
    }
    &:before {
      bottom: 64px;
    }
    .stepper {
      display: none;
    }
  }
}

@media only screen and (max-width: 424px) {
  .manualBox {
    flex-direction: column;
    height: auto;
    align-items: stretch;
    .search {
      margin-left: 0;
      margin-top: 5px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .header {
    .headerTitle {
      max-width: 100%;
    }
    .statusBlock {
      width: 100%;
    }
  }
}
