.container {
    display: flex;
}

.radioWrapper {
  width: 43px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new {
    backgroundColor: '#b8d3f3';
}

.draft {
    backgroundColor: '#d1d6dc';
}

.valid {
    backgroundColor: '#d7b8e5';
}

.accepted {
    backgroundColor: '#a9dcae';
}

.ignore {
    backgroundColor: '#e8aeae';
}

.display {
    backgroundColor: '#e9f5d6';
}
