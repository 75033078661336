.dialog {
  max-width: 100vw;
  padding: 0;
}

.header {
  font-weight: 600;
  font-size: 20px;
  height: 76px;
  background-color: var(--bg);
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 24px;
  flex-shrink: 0;
}

.info {
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  font-size: 12px;
  line-height: 20px;
  flex-shrink: 0;
  background-color: white;
  border-top: 1px solid rgba(237, 235, 233, 1);
  svg {
    margin-right: 5px;
  }
}

.wrapper {
  padding: 0 24px 24px 24px;
  background-color: rgba(250, 249, 248, 1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cell {
  width: 33%;
  white-space: normal;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
}

.row {
  height: auto;
  min-height: 43px;
  overflow: hidden;
  max-width: 100%;
}

.table {
  min-width: 70vw;
  box-shadow: var(--shadow);
  border-radius: 16px;
  margin-top: 16px;
}

@media only screen and (max-width: 1023px) {
  .header {
    margin-bottom: 0;
  }
}

.grid {
  margin: -12px;
  display: flex;
  flex-direction: column;
  .cell {
    overflow: auto;
    flex-grow: 1;
  }
  .row {
    border-bottom: 1px solid #00000010;
    &:last-of-type {
      border-bottom: none;
    }
    background-color: transparent!important;
  }
}
