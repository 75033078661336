.root {
  position: fixed;
  max-width: 100%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  z-index: 5;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow);
  border-radius: 6px;
  overflow: hidden;
  &.fullScreen {
    width: 100vw;
    height: 100%;
    max-height: 100%;
  }
  &.centered {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.overlay {
  background-color: #ffffff;
  opacity: 0.4;
  position: fixed;
  width: 100vw;
  height: 100%;
  max-width: 100vw;
  max-height: 100%;
  left: 0;
  top: 0;
  z-index: 4;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 100%;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.popup {
  position: relative;
  background-color: var(--bg);
  min-width: 232px;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  z-index: 2;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &.fullScreen {
    width: 100vw !important;
    height: 100vh !important;
  }
}

.header {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  color: var(--textSecondary);
}

.buttons {
  right: 24px;
  top: 24px;
  position: absolute;
  z-index: 1;
  display: flex;
  button {
    margin-left: 10px;
  }
}

.btn {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  svg {
    color: var(--textSecondary);
  }
}

.icon {
  color: var(--inputBorder);
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgTransparent);
  z-index: 2;
}

.collapsed {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;
  justify-content: center;
  box-shadow: var(--shadow);
  border-radius: 6px;
  border: 1px solid;
  border-color: var(--inputBorder);
  right: 25px;
  bottom: 30px;
  height: 62px;
  font-weight: 600;
  font-size: 18px;
  padding: 14px;
  background-color: rgba(255, 255, 255, 1);
}
