.wrapper {
  display: flex;
  flex-direction: column;
  width: 512px;
  max-width: 100%;
  color: var(--textPrimary);
}

.alertWrapper {
  margin-bottom: 10px;
  div {
    line-height: 16px;
  }
}
