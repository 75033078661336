.calendar {
  font-family: Segoe UI;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 12px;
  height: 100%;

  header {
    display: flex;
    justify-content: center;
  }
}

.year,
.month {
  height: 40px;
  width: 40px;
  padding: 8px 0;
  justify-self: center;
  align-self: center;
  vertical-align: middle;
  border: 2px solid transparent;
}

.day {
  width: 28px;
  height: 28px;
  padding: 4px 0px;
  border-radius: 6px;
  justify-self: center;
  align-self: center;
  vertical-align: middle;
  border: 1px solid transparent;
}

.year,
.month,
.day {
  &.selected {
    border: 1px solid var(--themePrimary);
  }

  &.today {
    background-color: var(--themePrimary);
    color: var(--bg);

    &.selected {
      background-color: var(--darkAlt);
    }

    &:hover {
      background-color: var(--themePrimary);
    }
  }

  &.disabled {
    color: var(--textDisabled);
    cursor: default;
  }
}

.weekHeader {
  width: 28px;
  height: 28px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 4px;
  vertical-align: middle;
  text-align: center;
  border: 2px solid transparent;
}

.year:hover,
.month:hover,
.day:hover {
  background-color: var(--gray20);
  cursor: pointer;
}

.goToCurrent {
  height: 16px;
  font-size: 12px;
  align-self: flex-end;

  &.disabled {
    color: var(--textDisabled);
    cursor: default;
  }
}

.sunday {
  color: #A4262C;
}

.headerButton {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  flex-grow: 1;
  text-align: center;
}

.headerArrowUp {
  width: 28px;
  height: 28px;
  &.disabled {
    cursor: default;
    & svg path {
      fill: var(--textDisabled);
    }
  }
}

.headerArrowDown {
  width: 28px;
  height: 28px;
  &.disabled {
    cursor: default;

    & svg path {
      fill: var(--textDisabled);
    }
  }
}

.calendarDaysGrid {
  display: grid;
  align-content: flex-start;
  grid-template-columns: repeat(7, 28px);
  grid-template-rows: 28px;
  margin: auto;
}

.calendarMonthsGrid,
.calendarYearsGrid {
  display: grid;
  width: 196px;
  height: 196px;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
