.wrapper {
  width: 440px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.content {
  padding: 20px 20px;
  margin-bottom: auto;
  overflow-y: auto;
}

.error {
  font-size: 12px;
  height: 12px;
  color: var(--error);
}

.filterBlock {
  display: grid;
  grid-template-columns: 30px minmax(0, 1fr);
  grid-template-areas: "close field" "close filter" "close values";
  grid-auto-rows: auto auto;
  gap: 10px 10px;
  margin-bottom: 12px;
}

.close {
  grid-area: close;
}

.field {
  grid-area: field;
}

.filter {
  grid-area: filter;
}

.values {
  grid-area: values;
}

.placeholder {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 32px;
}

@media only screen and (max-width: 440px) {
  .wrapper {
    width: 100vw;
    height: 100%;
  }
}
