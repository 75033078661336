.wrapper {
  width: 100%;
  height: 32px;
  display: flex;
  position: relative;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  font-size: 14px;

  &:focus, &.focus {
    .controlBox {
      outline: 2px solid var(--themePrimary);
      outline-offset: -2px;
      padding: 0;
    }
  }
}

.hidden {
  display: none;
}

.controlBox {
  flex-grow: 1;
  border-radius: 6px;
  display: flex;
  border: 1px solid var(--inputBorder);
  position: relative;
  overflow: hidden;
  align-items: center;

  &.hasError {
    border-color: var(--error);
  }
}

.valuesWrapper {
  flex-grow: 1;
  display: flex;
  //flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  line-height: 20px;
}

.input {
  //border: 1px solid gray;
  border: none;
  outline: none;
  padding: 0 5px;
  box-sizing: border-box;
  width: 100%;
}

.inputWrapper {
  flex: 1 0 30%;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  height: 24px;
  margin: 2px;
}

.value {
  background-color: var(--gray20);
  display: flex;
  box-sizing: border-box;
  flex: 1 0 60px;
  justify-content: space-between;
  height: 24px;
  margin: 2px;
  padding-left: 4px;

  .valueText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 2px;
  }

  .remove {
    display: flex;
    flex: 0 0 26px;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }
}

.clear {
  flex-shrink: 0;
  position: relative;

  &:after {
    flex-shrink: 0;
    content: '';
    display: block;
    width: 1px;
    background-color: var(--divider);
    height: 16px;
    position: absolute;
    right: 0;
  }

  svg {
    width: 12px;
    fill: var(--textSecondary);
  }
}

.chevron {
  flex-shrink: 0;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  position: relative;

  &.down {
    transform: rotate(-180deg);
  }
  svg {
    width: 100%;
    height: 100%;
    fill: var(--textSecondary);
  }
}

.listWrapper {
  position: fixed;
  z-index: 101;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  max-height: 360px;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  border-radius: 6px;
  overflow: hidden;
}

.list {
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.listItem {
  height: 36px;
  box-sizing: border-box;
  padding: 8px 12px 8px 8px;
  background-color: var(--bg);
  display: flex;
  cursor: pointer;
  align-items: center;
  line-height: 20px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  flex-shrink: 0;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .selected {
    span {
      font-weight: bold;
    }
    margin-right: 5px;
    flex-shrink: 0;
    fill: var(--themePrimary);
  }

  .icon {
    margin-right: 5px;
    flex-shrink: 0;
  }

  &:hover {
    background-color: var(--gray20);
  }
}

.noItem {
  display: flex;
  justify-content: center;
}

.singleValue {
  flex-grow: 1;
  padding-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placeholder {
  color: var(--textSecondary);
  padding: 0 5px;
  display: flex;
  align-items: center;
  user-select: none;
}

.loaderWrapper {
  transform: scale(0.5);
}

.postCheckIcon {
  position: absolute;
  right: 20px;
}

@media only screen and (max-height: 680px) {
  .list {
    max-height: 160px;
  }
}
