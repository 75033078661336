.btn {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 0;

  &:disabled {
    opacity: 0.5;
  }

  .label {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &:hover {
    outline: #0078D4;
  }

  .spacer {
    width: 8px;
    align-self: stretch;
  }

  .iconWrapper {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }

  &.right {
    flex-direction: row-reverse;
  }

  &.iconOnly {
    width: 32px;
    padding: 6px 8px;
    justify-content: center;
    .spacer {
      display: none;
    }
    .label {
      display: none;
    }
  }

  &.bordered {
    border: 1px solid var(--inputBorder);
    padding: 6px 20px;
  }
}

.icon {
  fill: var(--textPrimary);
}
