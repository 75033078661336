.form {
  width: 520px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.field {
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 20px;
  }
}

.hidden {
  display: none;
}

@media only screen and (max-width: 600px) {
  .form {
    width: auto;
  }
}
