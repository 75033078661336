.holder {
  width: 43px;
  &:before {
    content: " ";
    width: 1px;
    margin: 0 3px;
    background-color: var(--divider);
    display: block;
    position: absolute;
    right: 0;
    top: 8px;
    height: 24px;
  }
}
