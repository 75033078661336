.wrapper {
  width: 100%;
  height: 32px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;

  &:focus, &.focus {
    .controlBox {
      border: 2px solid var(--themePrimary);
      padding: 0;
    }
  }
}

.clear {
  flex-shrink: 0;
  position: relative;

  &:after {
    flex-shrink: 0;
    content: '';
    display: block;
    width: 1px;
    background-color: var(--divider);
    height: 16px;
    position: absolute;
    right: 0;
  }

  svg {
    width: 12px;
    fill: var(--textSecondary);
  }
}

.root {
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  height: 264px;
  width: 220px;
  z-index: 102;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  border-radius: 6px;

  &.showTime {
    height: 365px;
  }
}

.footerButtons {
  margin: 10px 0;
  padding-left: 12px;
  padding-right: 12px;
}

.button{
  width: 100%;
  height: 32px;
  border-radius: 6px;
}

.controlBox {
  flex-grow: 1;
  border-radius: 6px;
  display: flex;
  border: 1px solid var(--inputBorder);
  position: relative;
  padding: 1px;
  overflow: hidden;
  align-items: center;

  &.hasError {
    border-color: var(--error);
  }
}

.input {
  border: none;
  outline: none;
  padding: 0 5px;
  box-sizing: border-box;
  width: 100%;
}

.listWrapper {
  position: fixed;
  z-index: 101;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  max-height: 365px;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
}

.time {
  margin: 0 12px 0 12px;
  padding-bottom: 12px;
  div{
    top: auto !important;
    left: auto !important;
  }
}
