.root {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(50, 49, 48, 0.06);
  display: grid;
  grid-template-columns: 32px auto 32px;
  margin-bottom: 8px;
  color: var(--textPrimary);
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  border-radius: 6px;
  div {
    padding: 8px 0;
  }
  &.warning {
    background-color: #FFF4CE;
  }
  &.error {
    background-color: #FDE7E9;
  }
  &.success {
    background-color: #DFF6DD;
  }
}

.icon {
  margin: 8px;
  display: flex;
}
