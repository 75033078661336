.removeButton {
  margin-right: auto;
  margin-left: 0 !important;
}

.removeInvitedDialog,
.sendInvitationDialog {
  width: 560px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgTransparent);
  z-index: 2;
}

@media only screen and (max-width: 440px) {
  .removeInvitedDialog {
    width: calc(100% - 20px);

    div:last-child {
      flex-direction: column;
      justify-content: initial;

      button {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 16px;
        width: 243px;
      }

      .removeButton {
        justify-content: center;
        order: 1;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .sendInvitationDialog {
    width: calc(100% - 20px);
  }
}
