.rootWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.root {
  display: flex;
  align-items: flex-start;
  margin: auto 0;
}

.gridsWrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin: auto;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  .header {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}

.grid {
  display: grid;
  gap: 6px 0;
  grid-template-columns: repeat(7, 28px);
  padding-bottom: 10px;
  margin-top: 10px;
  flex-shrink: 0;
  .dayName {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }
  .day {
    width: 28px;
    height: 28px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 6px;
    padding-bottom: 2px;
    position: relative;
    &:hover {
      background-color: var(--gray20);
    }
    &.current {
      background-color: var(--themePrimary);
      color: #ffffff;
    }
    &.dot {
      &:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 3px;
        left: 13px;
        top: 100%;
        margin-top: 1px;
        background-color: #A19F9D;
        border-radius: 50%;
        z-index: 1;
      }
    }
  }
  .sunday {
    color: #A4262C;
  }
  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding: 14px;
  width: 340px;
  overflow: hidden;
  .listWrapper {
    overflow-y: auto;
    position: relative;
  }
}

.listHeader {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.listItem {
  display: flex;
  flex: 0 0 57px;
  align-items: center;
  border-bottom: 1px solid var(--divider);
  padding: 2px 15px;
  cursor: pointer;
  &:hover {
    background-color: var(--rowHover);
  }
  &:last-of-type {
    border-bottom: 1px solid transparent;
  }
}

.listItemContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 10px;
  justify-content: flex-start;
  line-height: 16px;
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.mobileRing {
  display: flex;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--theme3rd);
  text-transform: uppercase;
  line-height: 28px;
  align-self: center;
  flex-shrink: 0;
  overflow: hidden;
  &.ringCheck {
    background-color: unset;
    border: 2px solid var(--textPrimary);
    &.checked {
      border: none;
      svg {
        width: 100%;
      }
    }
  }
}
.arrow {
  div {
    width: 24px!important;
    height: 24px!important;
  }
}

.link {
  color: var(--themePrimary);
  font-weight: 600;
}

.loader {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgTransparent);
  z-index: 2;
}

@media only screen and (max-width: 440px) {
  .list {
    width: calc(100vw - 50px);
  }
}
