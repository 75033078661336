.root {
  grid-area: menu;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  transition: all 5s;
  font-size: 14px;
  flex-shrink: 0;
  position: relative;
  background-color: var(--bg);
}

.active {}

.link {
  position: relative;
  color: var(--textPrimary);
  height: 68px;
  width: 112px;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-color: transparent;
  font-weight: normal;
  font-size: 14px;
  flex-shrink: 0;
  background-color: var(--bg);
  overflow: visible;

  span {
    color: var(--textPrimary);
  }
  &.resources {
    margin-top: auto;
    height: 68px;
    .linkText {
      color: var(--themePrimary);
      white-space: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  &:hover {
    background-color: var(--rowHover);
  }

  &.collapsed {
    .linkText {
      display: none;
    }
  }
}

.icon {
  user-select: none;
  fill: var(--textPrimary);
  flex-shrink: 0;
}

.btnIcon {
  user-select: none;
  fill: var(--textPrimary);
}

.linkText {
  white-space: nowrap;
  line-height: 22px;
  font-weight: 600;
  font-size: 16px;
}

.mobile {
  position: absolute;
  .link {
    flex-direction: row;
    width: 100%;
    justify-content: left;
    padding: 10px 28px;
    height: 45px;
    .icon {
      margin-right: 16px;
    }
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}

.mobileBtn {
  height: 50px !important;
  grid-column: 1;
  grid-row: 1;
  padding: 0!important;
}

.mobileHolder {
  position: fixed;
  left: 0;
  top: 0;
  width: 305px;
  height: 100%;
  box-shadow: var(--shadow);
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 3;
  box-sizing: border-box;
  .wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--bg);
    z-index: 2;
    height: 100%;
  }
  &:before {
    content: "";
    position: absolute;
    background-color: #000000;
    opacity: 0.4;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
  }
}

.logoWrapper {
  height: 71px;
  padding: 0 15px;
  border-bottom: 1px solid var(--divider);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.close {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 24px;
  top: 24px;

  svg {
    fill: var(--textPrimary);
  }
}

.elements {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.version {
  font-size: 10px;
  line-height: 12px;
  color: var(--textSecondary);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
