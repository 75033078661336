.header {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 20px;
}

.text {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--textSecondary);
  margin-bottom: 16px;
  max-width: 512px;
  min-width: 300px;

}

.switch {
  &.all {
    border-bottom: 1px solid var(--divider);
  }
  display: flex;
  align-items: center;
  height: 36px;
  line-height: 20px;
  font-size: 14px;
}

.webBtn {
  display: flex;
  align-items: center;
  margin-right: auto;
  svg {
    margin-right: 5px;
  }
}

.format {
  display: flex;
  margin-bottom: 10px;
  button {
    width: 50%;
    margin: 0 5px;
    display: flex;
    align-items: center;
    height: 32px;
    font-weight: 600;
    white-space: nowrap;
    svg {
      margin: 0 5px;
    }
  }
}

.controls {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 440px) {
  .controls {
    flex-direction: column;
    .webBtn {
      margin-right: 0;
      order: 3;
    }
    button {
      margin-left: 0;
      margin-bottom: 5px;
      width: 100%;
      justify-content: center;
    }
  }
}
