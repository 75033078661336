.dialog {
  height: 600px!important;
  width: 760px!important;
  padding: 0!important;
  max-width: 100vw;
  max-height: 100%;
}

.content {
  padding: 20px 24px 0 24px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.footer {
  border-top: 1px solid var(--divider);
  height: 65px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.keepWrapper {
  display: flex;
  align-items: center;
}

.header {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--textPrimary);
}

.subHeader {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--textSecondary);
}

.controls {
  margin-left: auto;
  display: flex;
  button:not(:first-child) {
    margin-left: 8px;
  }
}

.checkBoxWrapper {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .footer {
    height: 88px;
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: center;
  }
  .controls {
    margin-left: 0;
    margin-bottom: 10px;
    button {
      flex-grow: 1;
    }
  }
}
