.root {
  display: flex;
  flex-direction: column;
  width: 290px;
  padding-bottom: 16px;
  max-height: 90vh;
  overflow: hidden;
  button {
    flex-shrink: 0;
  }
}

.header {
  border-bottom: 1px solid var(--divider);
  font-size: 12px;
  color: var(--textSecondary);
  line-height: 16px;
  overflow: hidden;
  padding: 15px 24px 5px 24px;
  height: 36px;
  flex-shrink: 0;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.item {
  font-size: 14px;
  height: 36px;
  padding: 0 24px;
  line-height: 36px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;

  &:hover, &.selected {
    background-color: var(--gray20);
  }

  svg {
    fill: var(--textSecondary);
  }

  .iconWrapper {
    display: flex;
    margin-right: 12px;

    &:hover {
      svg {
        fill: var(--themePrimary);
      }
    }
  }

  .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: auto;
    pointer-events: none;
  }

  button {
    flex-shrink: 0;
    margin-left: 8px;

    &:hover {
      svg {
        fill: var(--themePrimary);
      }
    }
  }
}

.viewBtn {
  fill: none;
}

svg{
  &.pinned {
    fill: var(--themePrimary);
  }
}

.changes {
  margin-bottom: 4px;
}

.btn {
  margin: 16px 16px 0 16px;
  white-space: nowrap;
}

.titleWrapper {
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 170px;
}

.withChanges {
  svg {
    fill: var(--themePrimary);
  }
}

.separator {
  display: flex;
  height: 16px;
  width: 1px;
  background-color: var(--divider);
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  .titleWrapper {
    svg {
      margin-right: 0;
    }
  }
}
