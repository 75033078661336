.wrapper {
  position: relative;
}

.overlay {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    background-color: #ffffff;
    opacity: 0.4;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }
}

.ldsRing {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid var(--themePrimary);
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--themePrimary) transparent transparent transparent;
}

.bg {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid var(--themePrimary);
  border-radius: 50%;
  opacity: 0.2;
  top: 0;
  left: 0;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
