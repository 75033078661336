.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10%;
  overflow: hidden;

  .image {
    max-width: 90%;
    overflow: hidden;
    display: flex;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    margin-top: 32px;
    font-size: 28px;
    font-weight: 600;
  }
  div {
    font-size: 16px;
    color: var(--textSecondary);
    text-align: center;
  }
  .reload {
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
  }
}

.refresh {
  margin-top: 20px;
  border: 1px solid var(--themePrimary);
}
