.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content {
  width: 100%;
  white-space: normal;
  overflow: hidden;
}

.btn {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--themePrimary);
  margin-top: 2px;
}
