.agreement {
  width: 560px;
}

.header {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}

.body {
  white-space: pre-wrap;
}
