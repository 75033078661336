.root {
  border-radius: 16px;
  height: 160px;
  background-color: var(--bg);
  grid-column: 1/-1;
  box-sizing: border-box;
  flex: 0 0 180px;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 196px 20px 20px;
  color: var(--textSecondary);
  font-weight: 400;
  font-family: "Segoe UI";
  background: {
    image: url("logo.svg");
    repeat: no-repeat;
    position: right 20px top 20px;
    size: auto 120px;
    repeat: no-repeat;
  }
  .textWrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .header {
    line-height: 28px;
    font-size: 28px;
    font-weight: 600;
    flex-shrink: 0;
  }
  .text {
    font-size: 18px;
    line-height: 24px;
    margin: auto 0;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
  .author {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    flex-shrink: 0;
  }
  .showMore {
    display: flex;
    margin: auto 0;
    text-decoration: underline;
    font-weight: 600;
    line-height: 24px;
    font-size: 18px;
    color: var(--themePrimary);
  }
}

@media only screen and (max-width: 1023px) {
  .root {
    padding: 16px 140px 16px 20px;
    .header {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    .text {
      font-size: 14px;
      line-height: 20px;
    }
    .author {
      font-size: 14px;
      line-height: 20px;
    }
    .showMore {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 440px) {
  .root {
    height: 120px;
    padding: 14px 100px 10px 10px;
    background-size: auto 96px;
    background-position: right 12px top 12px;
    .header {
      font-size: 12px;
      line-height: 16px;
    }
    .text {
      font-size: 10px;
      line-height: 12px;
    }
    .author {
      font-size: 10px;
      line-height: 12px;
    }
    .showMore {
      font-size: 10px;
      line-height: 12px;
    }
  }
}
