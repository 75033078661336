.root {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.wrapper {
  display: flex;
  margin-right: 16px;
  flex-direction: column;
  position: relative;
  &:hover {
    .btn {
      display: flex;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.icon {
  margin: 0 10px 0 20px;
  fill: var(--textPrimary);
}

.upload {
  position: absolute;
  right: 0;
  bottom: 0;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--textPrimary);
  width: 72px;
  height: 72px;
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
  }

  svg {
    fill: var(--textPrimary);
  }
}

.btn {
  display: none;
  margin-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding: 0;
  width: 20px!important;
  height: 20px;
  box-shadow: var(--smallShadow);
  border-radius: 50%;
  div,svg {
    width: 20px!important;
    height: 20px!important;
  }
}

@media only screen and (max-width: 768px) {
  .btn {
    display: flex!important;
  }
}
