.root {
  height: 52px;
  width: 100%;
  border: 1px dashed var(--divider);
  border-radius: 6px;
  background-color: var(--tableBgAlt);
  margin-top: 8px;
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &.remove {
    pointer-events: none;
    animation: blur 2s linear infinite;
  }
}

.progress {
  margin-top: 2px;
  height: 2px;
  flex: 0 0 2px;
  width: calc(100% - 60px);
  display: flex;
  position: relative;
  background-color: var(--divider);
  div {
    height: 100%;
    background-color: var(--themePrimary);
  }
}

.progressPlaceholder {
  width: 100%;
  height: 4px;
}

@keyframes blur {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.content {
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
  color: var(--themePrimary);
  font-size: 14px;
  font-weight: 600;
  flex-shrink: 0;
  span {
    max-width: 60%;
    margin-left: 5px;
    color: var(--textSecondary);
    white-space: nowrap;
    font-weight: 400;
    &.error {
      color: var(--error);
      padding: 0 5px;
      font-size: 12px;
      font-weight: 400;
      margin-left: auto;
    }
  }
  .fileName {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--textPrimary);
    font-weight: 400;
  }
  & > svg:first-of-type {
    margin-right: 5px;
  }
  svg {
    flex: 0 0 auto;
  }
}

.controls {
  display: flex;
  margin-left: auto;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
  button {
    width: 20px!important;
    height: 20px!important;
    margin-left: 5px;
  }
}

.errorIcon {
  fill: var(--error);
}
