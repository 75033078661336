.root {
  z-index: 10;
  &:before {
    content: '';
    background-color: #ffffff;
    opacity: 0.4;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 2;
  }
}

.popup {
  background-color: var(--bg);
  height: 100%;
  max-width: 100vw;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 3;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  padding: 20px 24px;
  span {
    margin-left: 10px;
  }
}

.headerCollapsed {
  padding-left: 7px;
  padding-top: 20px;
  width: 24px;
}

.header,
.headerCollapsed {
  button {
    svg {
      fill: var(--themeSecondary);
    }
    &:hover {
      svg {
        fill: var(--themePrimary);
      }
    }
  }
  display: inline-flex;
  div {
    cursor: default;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.close {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 24px;
  top: 24px;
}

.icon {
  fill: var(--textSecondary);
}

.controls {
  display: flex;
  min-height: 64px;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: auto;
  border-top: 1px solid var(--divider);
  padding: 0 24px;
  align-items: center;
  overflow: hidden;
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & button {
    margin-right: 16px;
    font-weight: 600;
  }
}
.helper {
  font-size: 12px;
  font-weight: 400;
  color: var(--textSecondary);
  line-height: 16px;
  flex-grow: 1;
}

@media only screen and (max-width: 440px) {
  .popup {
    width: 100vw;
    height: 100%;
  }
  .controls {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    .buttons {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 15px;
      width: 100%;
      button {
        margin: 0;
      }
    }
    .helper {
      margin-top: 8px;
      flex-grow: 0;
      width: 100%;
    }
  }
}
