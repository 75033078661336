.btn {
  width: 40px;
  height: 20px;
  border-radius: 10px;
  flex: 0 0 auto;
  border: 1px solid var(--inputBorder);
  position: relative;
  margin-right: 10px;

  &.checked {
    border-color: var(--themePrimary);
    background-color: var(--themePrimary);

    .circle {
      background-color: #fff;
      transform: translate(20px);
    }
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  background-color: var(--inputBorder);
  transition: all 200ms;
}
