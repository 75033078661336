$search-padding-h: 42px;

//icons
.searchIcon,
.warningIcon,
.dismissIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  width: $search-padding-h;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchIcon {
  pointer-events: none;
  left: 0;
}

.warningIcon,
.dismissIcon {
  right: 0;
  cursor: pointer;
}

//group
.searchGroup {
  position: relative;
  height: 32px;
  width: 100%;

  .dismissIcon {
    display: none;
  }

  &:hover {
    .searchIcon,
    .warningIcon {
      svg {
        fill: var(--themePrimary);
      }
    }
  }

  svg {
    fill: var(--textSecondary);
    display: block;
  }

  input {
    padding-left: $search-padding-h;
    padding-right: $search-padding-h;
    color: var(--textPrimary);
    border-radius: 16px;

    &:hover {
      border-color: var(--textPrimary);
    }

    &:focus {
      padding-left: 12px;
      outline: none;
      border-color: var(--themePrimary);

      + .searchIcon {
        display: none;
      }
    }
  }

  &.filled {
    input {
      padding-left: 12px;
    }

    .searchIcon,
    .warningIcon {
      display: none;
    }

    .dismissIcon {
      display: flex;
    }
  }

  &.disabled {
    input {
      padding-right: 8px;
      border-color: var(--divider);
      background-color: var(--divider);
      color: var(--textDisabled);
      cursor: not-allowed;
    }

    .searchIcon {
      svg {
        fill: var(--textDisabled);
      }
    }

    .warningIcon {
      display: none;
    }
  }
}
