.root {
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--themePrimary);
  overflow: hidden;
  &.success {
    background-color: #107C10;
    & > .hider {
      display: none;
    }
  }
}

.circle {
  position: absolute;
  left: 1px;
  top: 1px;
  border-radius: 50%;
  z-index: 2;
  width: 70px;
  height: 70px;
  background-color: var(--bg);
}

.hider {
  position: absolute;
  left: 50%;
  opacity: 0.5;
  width: 36px;
  height: 72px;
  background-color: var(--bg);
  transform-origin: left center;
  display: flex;
  flex-direction: column;
  animation: spin linear 1.2s infinite;
  &:before {
    content: '';
    width: 72px;
    height: 36px;
    background-color: var(--bg);
    display: flex;
    align-self: flex-end;
  }
}

.text {
  z-index: 3;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
