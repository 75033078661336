.root {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;

  div {
    text-align: center;
  }
}
