
.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: stretch;
  margin-bottom: 10px;
  overflow-x: auto;
  &.closed {
    .content {
      display: none;
    }
    .header {
      &:before {
        transform: rotate(-90deg);
      }
    }
  }
}

.header {
  cursor: pointer;
  padding-left: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--textSecondary);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 10px;
    transition: all 100ms;
    background: {
      position: center center;
      image: url("./chevronDown.svg");
      repeat: no-repeat;
    }
  }
}

.content {
  &.disabled {
    &:before {
      content: '';
      opacity: 0.5;
      background-color: var(--bg);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }
  }
  overflow-x: auto;
  position: relative;
  padding: 10px;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}
