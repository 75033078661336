.root {
  display: flex;
  position: relative;
}

.menu {
  z-index: 10;
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  box-shadow: var(--shadow);
  border-radius: 6px;
  width: min-content;
}

.button {

}
