.field {
  display: flex;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  position: absolute;
  align-items: center;
  left: 0;
  top:0;
  height: 40px;
  padding-left: 12px;
  div {
    width: 33.3%;
    flex-shrink: 0;
  }
}
