.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 100%;
  position: relative;
  flex-shrink: 0;

  span {
    display: flex;
  }

  &.header {
    &:before {
      content: " ";
      width: 1px;
      margin: 0 3px;
      background-color: var(--divider);
      display: block;
      position: absolute;
      right: 0;
      top: 8px;
      height: 24px;
    }
  }
}
