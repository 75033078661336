.accountinfo {
  width: 314px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 100;
  background-color: var(--bg);
  color: var(--textPrimary);
  box-shadow: var(--accountInfoShadow);
  border-radius: 6px;

  .header {
    font-size: 12px;
    border-bottom: 1px solid #edebe9;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    height: 50px;
    display: flex;
    align-items: center;

    .signout {
      cursor: pointer;
      color: var(--themePrimary);
    }
  }

  .userWrapper {
    margin-top: 12px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid var(--divider);

    .user {
      border: 1px solid var(--textPrimary);
      border-radius: 50%;
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 10px;
      font-size: 26px;
    }

    .namebox {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      margin-top: 10px;

      .username {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        word-wrap: break-word;
      }

      .email {
        font-weight: 400;
        font-size: 12px;
        color: var(--textSecondary);
        line-height: 16px;
        margin-top: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.signoutConfirmation {
  width: 560px;
}

.button {
  font-size: 14px;
  font-weight: 400;
  color: var(--textPrimary);
  width: 100%;
  height: 49px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
    margin-right: auto;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &:hover {
    background: #edebe9;
    svg {
      fill: var(--themePrimary);
    }
  }
}

.email {
  font-weight: bold;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
  }
}

@media only screen and (max-width: 580px) {
  .signoutConfirmation {
    width: 350px;
  }
}
