.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: stretch;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  background-color: var(--bg);
  padding-bottom: 5px;
  > div {
    flex-grow: 1;
  }
  &.fullScreen {
    left: 0;
    top: 0;
    padding: 0 12px;
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
  }
}

.header {
  border-bottom: 1px solid var(--divider);
  height: 42px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.mobile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.zoom {
  margin-left: auto;
  padding: 12px;
}
