.lockAlert {
  display: flex;
  min-height: 32px;
  width: 100%;
  background: #ffffff;
  font-size: 14px;
  color: var(--textPrimary);
  align-items: center;
  padding: 0 12px;
  line-height: 16px;
  flex-shrink: 0;
  position: sticky;
  top: 43px;
  z-index: 1;
  opacity: 0.98;
  .text {
    margin: 0 5px;
  }
}

.isWarning {
  background-color: #FFF4CE;
  svg {
    fill: var(--warningBg);
  }
}

.lockIcon {
  margin-right: 8px;
  display: flex;
}

@media only screen and (max-width: 440px) {
  .lockAlert {
    top: 0;
  }
}
