.listPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: var(--bg);
  &.fullScreen {
    z-index: 2;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--bg);
  }
}

.searchWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1;
}

.footer {
  display: flex;
  height: 36px;
  flex-shrink: 0;
  border-top: 1px solid var(--divider);
  align-items: center;
  color: var(--textSecondary);
  padding: 0 15px;
  font-size: 12px;
  filter: drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13));
  .records {
    margin-right: 5px;
  }
  .selectedText {
    font-weight: 600;
    color: var(--textPrimary);
  }
}

.header {
  display: flex;
  height: 46px;
  padding-left: 20px;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid var(--divider);
}

.subHeader {
  min-height: 46px;
  display: flex;
  border-bottom: 1px solid var(--divider);
  flex-shrink: 0;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  padding: 0 5px;
  position: relative;
  .headerButtons {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.content {
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.panel {
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.long {
  grid-column: 1/-1;
}

.mobileList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: auto;
  flex-grow: 1;
  position: relative;
}

.tableControls {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-left: auto;
}

.subgrid {
  .mobileList {
    padding: 0;
  }

  .content {
    margin: 0;
  }

  .viewBtn {
    max-width: 200px;
  }
}

.listItem {
  display: flex;
  flex: 0 0 57px;
  align-items: center;
  border-bottom: 1px solid var(--divider);
  padding: 2px 15px;
  cursor: pointer;
  &.listItemSelected {
    background-color: var(--rowHover);
  }
}

.listItemContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 10px;
  justify-content: flex-start;
  line-height: 16px;
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.mobileRing {
  display: flex;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--theme3rd);
  text-transform: uppercase;
  line-height: 28px;
  align-self: center;
  flex-shrink: 0;
  overflow: hidden;
  &.ringCheck {
    background-color: unset;
    border: 2px solid var(--textPrimary);
    &.checked {
      border: none;
      svg {
        width: 100%;
      }
    }
  }
}

.more {
  fill: var(--textPrimary);
}

.link {
  color: var(--themePrimary);
  font-weight: 600;
}

.loaderAbsolute {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgTransparent);
  z-index: 2;
}

.loader {
  margin: auto 0;
  position: sticky;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;

  svg {
    height: 125px;
    margin-bottom: 12px;
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--textPrimary);
  }

  button {
    background-color: var(--bg);
    border-radius: 6px;
    color: var(--textPrimary);
    border: 1px solid var(--inputBorder);
    height: 32px;
    padding: 0 20px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    margin-top: 12px;
    flex-shrink: 0;
  }
}

.headerButtons {
  flex-shrink: 0;
  height: 42px;
  .filtersActive {
    color: var(--themePrimary);

    svg {
      fill: var(--themePrimary);
    }
  }
}


.iconButtonMobile {
  width: 36px;
  padding: 0;
  margin: 0;
}

.subGridCreate {
  margin-right: 16px;
  padding: 0 10px;
}

.divider {
  width: 1px;
  height: 24px;
  background-color: var(--divider);
  align-self: center;
}

.addIcon {
  svg {
    fill: var(--themePrimary);
  }
}

.selectAll {
  margin-right: auto;
}

.selectedLegend {
  border-top: 1px solid var(--divider);
  margin-top: auto;
  font-size: 12px;
  color: var(--textSecondary);
  padding: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  filter: drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13));
}

.separator {
  display: flex;
  height: 16px;
  width: 1px;
  background-color: var(--divider);
  margin: auto 0;
}

.filterIndicator {
  position: absolute;
  right: -3px;
  top: -2px;
  border-radius: 50%;
  color: var(--bg);
  background-color: #005A9E;
  width: 13px;
  height: 13px;
  font-size: 10px;
  font-weight: 600;
  border: 1px solid var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 10px;
  padding-bottom: 1px;
}

@media only screen and (max-width: 374px) {
  .subGridCreate {
    padding: 0 5px !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 440px) {
  .subHeader {
    min-height: 43px;
  }
  .viewBtn {
    max-width: 110px;
    margin-right: unset;
  }
  .subgrid {
    .viewBtn {
      max-width: 110px;
      margin-right: unset;
    }
    .subHeader {
      margin: 0;
    }
  }
  .loader {
    max-height: 100%;
  }
}

.mobileLine {
  display: flex;
  a {
    overflow: visible;
  }
}
