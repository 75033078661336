.header {
  grid-area: header;
  display: flex;
  height: 70px;
  flex-shrink: 0;
  padding: 0 15px;
  background-color: var(--bg);
  color: var(--textPrimary);
}

.logoBlock {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 22px;
  user-select: none;
  flex-shrink: 0;
}

.headerText {
  margin-left: 13px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  div {
    display: flex;
  }

  strong {
    margin-left: auto;
  }
}

.rightBlock {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.user {
  border: 1px solid var(--textPrimary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  flex-shrink: 0;
  cursor: pointer;
}

.link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.envBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 10px;
  width: 81px;
  .env {
    font-size: 28px;
    font-weight: 600;
  }
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
  }

  svg {
    fill: var(--textPrimary);
  }
}

@media only screen and (max-width: 768px) {
  .envBlock {
    width: auto;
  }
  .userName {
    display: none;
  }
  .separator {
    display: none;
  }
  .icon {
    margin-left: 5px;
  }
  .headerText {
    margin-left: 9px;
  }
  .chevron {
    display: none;
  }
  .user {
    margin: 0;
  }
  .link {
    margin-right: 15px;
  }
  .header {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
  }
  .logoBlock {
    grid-column-start: 2;
    justify-self: center;
  }
}
