.disableClass {
  opacity: 0.5;
  pointer-events: none;
}

.technicalElementForTour {
  position: absolute;
  top: -1000px;
  left: -1000px;
}
