.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .flex {
    display: flex;
  }
}

.label {
  font-weight: 400;
  margin-bottom: 5px;
  order: 1;

  &.required {
    &:after {
      content: '*';
      color: var(--error);
    }
  }
}

.error {
  color: var(--error);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  &.absolute {
    position: absolute;
    left: 0;
    top: 100%;
  }
}
