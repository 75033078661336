body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body, html {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  color: var(--textPrimary);
}

* {
  font-family: "Segoe UI";
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline-color: var(--themePrimary);
}

.ck-content ul {
  padding-left: 20px;
  margin: 0 10px;
}

input, textarea {
  font-family: "Segoe UI";
  font-size: 14px;
  color: var(--textPrimary);
  border-color: var(--inputBorder);

  &:disabled {
    opacity: 0.6;
  }
}

button {
  font-family: "Segoe UI";
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  color: var(--textPrimary);
}

a {
  color: var(--themePrimary);
  font-weight: 600;
  text-transform: none;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

#root {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.root-span {
  display: flex;
  color: inherit;
}

strong {
  font-weight: 600;
}

@media only screen and (max-width: 440px) {
  input, textarea, .ck-editor__main {
    font-size: 16px !important;
  }
}
